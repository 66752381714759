body {font-family: "Inter", sans-serif !important;  overflow-x: hidden;}
img{max-width: 100%;}
a, a:focus, a:active{text-decoration: none !important; outline: none;}


.badge-custom {
  border-radius: 14px;
  background: rgba(3, 169, 244, 0.12);
  color: rgba(33, 150, 243, 1);
  font-size: 13px;
  font-weight: 400;
  text-align: left;
  padding: 4px 14px;
  display: inline-block;
}

.input-custom {
  background: #ffffff;
  border: 1px solid rgba(217, 217, 217, 0.78);
  border-radius: 10px;
  color: rgba(56, 56, 56, 1);
  padding: 10px;
  outline: none;
}

/*INFO: banner section styles*/
.banner-section {
  min-height: 939px;
  padding-top: 120px;
}

.banner-section__container .description-tag {
  background-color: rgba(3, 169, 244, 0.12);
  border-radius: 9px;
  padding: 6px 10px;
  width: fit-content;
}

.description-tag .description-tag__badge {
  border-color: rgba(0, 188, 212, 0.7);
  border-radius: 9px;
  border-style: solid;
  background: rgba(0, 176, 255, 1);
  color: #ffffff;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 17px;
  padding-left: 6px;
  padding-right: 6px;
}

.description-tag .description-tag__text {
  margin-bottom: 0;
  color: rgba(0, 176, 255, 1);
  margin-left: 6px;
  font-size: 15px;
}

.banner-section .banner-section__title .title-text {
  font-weight: bold;
  text-align: center;
  font-size: 35px;
  width: 100%;
  margin-top: 12px;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .banner-section .banner-section__title .title-text {
    font-size: 40px;
  }
}

@media (min-width: 1024px) {
  .banner-section .banner-section__title .title-text {
    font-size: 55px;
  }

  .banner-section .banner-section__title .title-text {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1200px) {
}

.banner-section .banner-section__title .description-text {
  margin-top: 20px;
  color: rgba(88, 88, 88, 0.81);
  text-align: center;
}

.banner-section .banner-section__describe {
  width: 100%;
  margin-top: 24px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (min-width: 768px) {
  .banner-section .banner-section__describe {
    max-width: 467px;
    flex-direction: row;
  }
}

.banner-section__describe .banner-section__describe-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.banner-section__describe .banner-section__describe-item:not(:first-child) {
  margin-top: 24px;
}

@media (min-width: 768px) {
  .banner-section__describe .banner-section__describe-item:not(:first-child) {
    margin-left: 20px;
    margin-top: 0;
  }
}

.banner-section__describe .banner-section__describe-item .describe-icon {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background-color: #ffffff;
  box-shadow: 1px 1px 9px 0 rgba(0, 0, 0, 0.36);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.banner-section__describe .banner-section__describe-item .describe-icon i{
  font-size: 18px;
  color: #1178ff;
}

.banner-section__describe .banner-section__describe-item .describe-text {
  margin-bottom: 0;
  margin-left: 14px;
  font-size: 15px;
  color: rgba(88, 88, 88, 0.81);
}

.banner-section .banner-section__cta {
  margin-top: 40px;
  z-index: 2;
  position: relative;
}

.banner-section .banner-section__cta i{
  vertical-align: -1px;
}

.banner-section .banner-image {
  position: absolute;
  width: 100%;
  height: 611px;
  bottom: 0;
  background-image: url("../public/assets/images/header-banner.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
}

@media (min-width: 768px) {
  .banner-section .banner-image {
    bottom: 10%;
  }
}

@media (min-width: 1200px) {
  .banner-section .banner-image {
    width: 1104px;
  }
}

.setting-icon-1 {
  width: 48px;
  height: 48px;
  -webkit-mask-size: 100% 100%;
  -webkit-mask-position: 0% 0%;
  position: absolute;
  top: 28%;
  left: 10%;
  display: none;
}

.setting-icon-1 i{
  opacity: 0.7;
  color: rgba(0, 153, 255, 0.44);
  font-size: 45px;
}

@media (min-width: 768px) {
  .setting-icon-1 {
    display: block;
  }
}

.setting-icon-2 {
  width: 62px;
  height: 62px;
  -webkit-mask-size: 100% 100%;
  -webkit-mask-position: 0% 0%;
  position: absolute;
  top: 70%;
  right: 10%;
  display: none;
}

.setting-icon-2 i{
  opacity: 0.7;
  color: rgba(0, 153, 255, 0.44);
  font-size: 55px;
}

@media (min-width: 768px) {
  .setting-icon-2 {
    display: block;
  }
}

.left-side-frame {
  position: absolute;
  background-image: url("../public/assets/images/banner-left-side-frame.png");
  width: 300px;
  height: 550px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  left: 0;
  bottom: -25%;
  display: none;
}

@media (min-width: 768px) {
  .left-side-frame {
    display: block;
  }
}

.right-side-frame {
  position: absolute;
  background-image: url("../public/assets/images/banner-right-side-frame.png");
  width: 200px;
  height: 604px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  right: 0;
  top: 10%;
  display: none;
}

@media (min-width: 768px) {
  .right-side-frame {
    display: block;
  }
}

/*INFO: products section styles*/
.section-title {
  width: 100%;
  z-index: 1;
  position: relative;
}

.section-title .section-title__image {
  max-width: 262px;
  width: 100%;
  display: none;
}

@media (min-width: 768px) {
  .section-title .section-title__image {
    display: block;
  }
}

.section-title .section-title__image .section-title__image-wrapper {
  width: 100%;
  padding-bottom: calc(162 / 262 * 100%);
  position: relative;
}

.section-title .section-title__image .section-title__image-wrapper > img {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.section-title .section-title__text {
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .section-title .section-title__text {
    margin-left: 24px;
    margin-right: 0;
  }
}

.section-title .section-title__text > .title-text {
  font-weight: bold;
  line-height: 1.1;
  color: #000000;
  margin-bottom: 10px;
  text-align: center;
  font-size: 30px;
}

@media (min-width: 768px) {
  .section-title .section-title__text > .title-text {
    text-align: left;
  }
}

@media (min-width: 1024px) {
  .section-title .section-title__text > .title-text {
    font-size: 40px;
  }
}

.section-title .section-title__text > .description-text {
  color: rgba(88, 88, 88, 0.81);
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}

@media (min-width: 768px) {
  .section-title .section-title__text > .description-text {
    text-align: left;
  }
}

.product-section__frame {
  background-image: url("../public/assets/images/product-section-frame.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 300px;
  height: 300px;
  position: absolute;
  display: none;
}

@media (min-width: 768px) {
  .product-section__frame {
    display: block;
    right: -30%;
    top: -20%;
  }
}

@media (min-width: 1024px) {
  .product-section__frame {
    right: -15%;
    top: -15%;
  }
}

@media (min-width: 1200px) {
  .product-section__frame {
    right: 100px;
  }
}

.product-section__body {
  max-width: 1170px;
  margin: auto;
}

.product-section__body .product-section__body-cta {
  display: flex;
  justify-items: center;
  align-items: center;
  flex-direction: column;
  margin-top: 60px;
  padding-right: 10px;
  padding-left: 10px;
}

@media (min-width: 768px) {
  .product-section__body .product-section__body-cta {
    flex-direction: row;
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1024px) {
  .product-section__body .product-section__body-cta {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.product-section__body .product-section__body-cta > .btn-secondary {
  font-size: 21px;
  font-weight: bold;
  border-radius: 100px;
  box-shadow: 0 2px 20px 11px rgba(0, 68, 166, 0.1);
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: transform 200ms ease-out;
  -webkit-transition: transform 200ms ease-out;
  z-index: 1;
  position: relative;
}

@media (min-width: 768px) {
  .product-section__body .product-section__body-cta > .btn-secondary {
    width: calc(100% / 3);
  }
}

.product-section__body .product-section__body-cta > .btn-secondary.active {
  background-color: rgba(15, 122, 255, 1);
  color: #ffffff;
}

.product-section__body-cta > .btn-secondary:not(:first-child) {
  margin-top: 35px;
}

@media (min-width: 768px) {
  .product-section__body-cta > .btn-secondary:not(:first-child) {
    margin-left: 40px;
    margin-top: 0;
  }
}

@media (min-width: 1024px) {
  .product-section__body-cta > .btn-secondary:not(:first-child) {
    margin-left: 60px;
  }
}

@media (min-width: 1200px) {
  .product-section__body-cta > .btn-secondary:not(:first-child) {
    margin-left: 90px;
  }
}

.product-section__body .product-section__body-cta > .btn-secondary:active {
  transform: translateY(3px);
}

.product-section__body-detail .pc-games-slide {
  width: 100%;
  display: grid;
  gap: 24px;
  padding: 60px 0 30px 0;
}

@media (min-width: 768px) {
  .product-section__body-detail .pc-games-slide {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .product-section__body-detail .pc-games-slide {
    grid-template-columns: repeat(3, 1fr);
    padding-left: 20px;
    padding-right: 20px;
  }
}

.pc-games-slide__item {
  width: 100%;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.07);
  border-radius: 13px;
  background-color: #ffffff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  color: inherit;
  transition: all .2s ease-in-out;
}

.pc-games-slide__item:hover{
  box-shadow: 0 0 20px 8px rgba(0, 0, 0, 0.07);
  transform: scale(1.03);
}

.pc-games-slide__item a{

}

.pc-games-slide__item .item-header {
  display: flex;
  justify-content: flex-start;
}

.pc-games-slide__item .item-header .item-header__avatar {
  flex-basis: 65px;
  flex-grow: 0;
  flex-shrink: 0;
  width: 65px;
  height: 65px;
  border-radius: 10px;
  overflow: hidden;
}

.pc-games-slide__item .item-header .item-header__avatar > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.pc-games-slide__item .item-header .item-header__detail {
  margin-left: 16px;
}

.pc-games-slide__item .item-header .item-header__detail > .game-name {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.3;
  text-align: left;
  margin-bottom: 2px;
}

.pc-games-slide__item .item-header .item-header__detail .game-rating {
  color: #000000;
  font-size: 15px;
  font-weight: bold;
  text-align: left;
  margin-bottom: 0;
}

.pc-games-slide__item .item-header .item-header__detail > .badge-custom {
  margin-top: 14px;
}

.item-header__detail .game-rating > .fa-star {
  color: rgba(230, 126, 34, 1);
}

.item-header__detail .game-downloaded {
  color: rgba(88, 88, 88, 0.81);
  font-size: 0.8rem;
  font-weight: 400;
  text-align: left;
  margin-bottom: 0;
}

.pc-games-slide__item .item-body-text {
  font-size: 15px;
  font-weight: 400;
  text-align: left;
  margin-top: 14px;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: rgba(0,0,0, 0.5);
}

.product-section__body-detail .multimedia-slide {
  width: 100%;
  padding: 60px 10px;
}

@media (min-width: 1024px) {
  .product-section__body-detail .pc-games-slide {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.multimedia-slide__item {
  display: flex;
  padding: 20px 0;
  border-bottom: 1px solid rgba(88, 88, 88, 0.11);
}

@media (min-width: 768px) {
}

.multimedia-slide__item > .item-image {
  width: 80px;
  height: 80px;
  flex-shrink: 0;
}

.multimedia-slide__item > .item-image > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.multimedia-slide__item > .multimedia-slide__item-content > .item-detail {
  margin-left: 14px;
  margin-right: 0;
  flex: 2;
}

@media (min-width: 1024px) {
  .multimedia-slide__item > .multimedia-slide__item-content > .item-detail {
    margin-right: 14px;
  }
}

.item-detail__header .item-detail__header-title {
  font-size: 21px;
  font-weight: bold;
  text-align: left;
  margin-bottom: 0;
  margin-top: 10px;
}

@media (min-width: 576px) {
  .item-detail__header .item-detail__header-title {
    margin-left: 10px;
    margin-top: 0;
  }
}

.multimedia-slide__item-content > .item-detail > .item-detail__content {
  font-size: 15px;
  font-weight: 400;
  text-align: left;
  margin-top: 8px;
  margin-bottom: 0;
}

.multimedia-slide__item-content > .item-cta {
  margin-left: 14px;
  margin-top: 8px;
  width: fit-content;
}

@media (min-width: 1024px) {
  .multimedia-slide__item-content > .item-cta {
    margin-left: 0;
    margin-top: 0;
  }
}

.multimedia-slide__item-content > .item-cta > .btn-custom {
  width: 100%;
}

.multimedia-slide__item-content > .item-cta > .item-cta__download-info {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 0;
  margin-left: 0;
  margin-top: 6px;
}

@media (min-width: 576px) {
  .multimedia-slide__item-content > .item-cta > .item-cta__download-info {
    margin-top: 6px;
    margin-left: 0;
  }
}

@media (min-width: 768px) {
  .multimedia-slide__item-content > .item-cta > .item-cta__download-info {
    margin-top: 0;
    margin-left: 6px;
  }
}

@media (min-width: 1024px) {
  .multimedia-slide__item-content > .item-cta > .item-cta__download-info {
    margin-top: 6px;
    margin-left: 0;
  }
}

/*INFO: features section styles*/
.features-section {
  background: linear-gradient(
          180deg,
          rgba(221, 240, 255, 1) 0%,
          rgba(255, 255, 255, 0.16) 100%
  );
  padding: 60px 0;
  margin-top: 100px;
}

.features-section .features-section__frame {
  position: absolute;
}

.features-section .features-section__frame .frame-item {
  -webkit-mask-position: 0% 0%;
  -webkit-mask-size: 100% 100%;
  width: 100%;
  height: 100%;
}

.features-section__frame.frame-1 {
  left: -3368.5px;
  bottom: 100%;
  width: 7658px;
  height: 85px;
  z-index: 1;
}

.features-section__frame.frame-1 .frame-item {
  -webkit-mask-image: url(data:image/svg+xml;base64,PHN2ZyBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSdub25lJyB3aWR0aD0nMTE1NScgaGVpZ2h0PScyODEnIHZpZXdCb3g9JzAgMCAxMTU1IDI4MScgZmlsbD0nbm9uZScgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48cGF0aCBmaWxsLXJ1bGU9J2V2ZW5vZGQnIGNsaXAtcnVsZT0nZXZlbm9kZCcgZD0nTTAgMTA3LjgyNkwxOSA4OS44MjY0QzM5IDcyLjgyNjQgNzcgMzcuODI2NCAxMTYgNjYuODI2NEMxNTQgOTUuODI2NCAxOTMgMTg3LjgyNiAyMzEgMTk5LjgyNkMyNzAgMjExLjgyNiAzMDggMTQxLjgyNiAzNDcgODMuODI2NEMzODUgMjYuODI2NCA0MjQgLTIwLjE3MzYgNDYyIDguODI2NDNDNTAxIDM3LjgyNjQgNTM5IDE0MS44MjYgNTc4IDE3MC44MjZDNjE2IDE5OS44MjYgNjU1IDE1My44MjYgNjkzIDEzMC44MjZDNzMyIDEwNy44MjYgNzcwIDEwNy44MjYgODA5IDEzMC44MjZDODQ3IDE1My44MjYgODg2IDE5OS44MjYgOTI0IDE5My44MjZDOTYzIDE4Ny44MjYgMTAwMSAxMzAuODI2IDEwNDAgMTEyLjgyNkMxMDc4IDk1LjgyNjQgMTExNyAxMTguODI2IDExMzYgMTMwLjgyNkwxMTU1IDE0MS44MjZWMjgwLjgyNkgxMTM2QzExMTcgMjgwLjgyNiAxMDc4IDI4MC44MjYgMTA0MCAyODAuODI2QzEwMDEgMjgwLjgyNiA5NjMgMjgwLjgyNiA5MjQgMjgwLjgyNkM4ODYgMjgwLjgyNiA4NDcgMjgwLjgyNiA4MDkgMjgwLjgyNkM3NzAgMjgwLjgyNiA3MzIgMjgwLjgyNiA2OTMgMjgwLjgyNkM2NTUgMjgwLjgyNiA2MTYgMjgwLjgyNiA1NzggMjgwLjgyNkM1MzkgMjgwLjgyNiA1MDEgMjgwLjgyNiA0NjIgMjgwLjgyNkM0MjQgMjgwLjgyNiAzODUgMjgwLjgyNiAzNDcgMjgwLjgyNkMzMDggMjgwLjgyNiAyNzAgMjgwLjgyNiAyMzEgMjgwLjgyNkMxOTMgMjgwLjgyNiAxNTQgMjgwLjgyNiAxMTYgMjgwLjgyNkM3NyAyODAuODI2IDM5IDI4MC44MjYgMTkgMjgwLjgyNkgwVjEwNy44MjZaJyBmaWxsPScjMDA5OUZGJy8+PC9zdmc+);
  background: linear-gradient(
          180deg,
          rgba(33, 150, 243, 0.34) 0%,
          rgba(221, 240, 255, 1) 100%
  );
}

.features-section__frame.frame-2 {
  left: -3340px;
  bottom: 100%;
  width: 7373px;
  height: 133px;
}

.features-section__frame.frame-2 .frame-item {
  -webkit-mask-image: url(data:image/svg+xml;base64,PHN2ZyBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSdub25lJyB3aWR0aD0nMTE1NScgaGVpZ2h0PScyODEnIHZpZXdCb3g9JzAgMCAxMTU1IDI4MScgZmlsbD0nbm9uZScgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48cGF0aCBmaWxsLXJ1bGU9J2V2ZW5vZGQnIGNsaXAtcnVsZT0nZXZlbm9kZCcgZD0nTTAgMTA3LjgyNkwxOSA4OS44MjY0QzM5IDcyLjgyNjQgNzcgMzcuODI2NCAxMTYgNjYuODI2NEMxNTQgOTUuODI2NCAxOTMgMTg3LjgyNiAyMzEgMTk5LjgyNkMyNzAgMjExLjgyNiAzMDggMTQxLjgyNiAzNDcgODMuODI2NEMzODUgMjYuODI2NCA0MjQgLTIwLjE3MzYgNDYyIDguODI2NDNDNTAxIDM3LjgyNjQgNTM5IDE0MS44MjYgNTc4IDE3MC44MjZDNjE2IDE5OS44MjYgNjU1IDE1My44MjYgNjkzIDEzMC44MjZDNzMyIDEwNy44MjYgNzcwIDEwNy44MjYgODA5IDEzMC44MjZDODQ3IDE1My44MjYgODg2IDE5OS44MjYgOTI0IDE5My44MjZDOTYzIDE4Ny44MjYgMTAwMSAxMzAuODI2IDEwNDAgMTEyLjgyNkMxMDc4IDk1LjgyNjQgMTExNyAxMTguODI2IDExMzYgMTMwLjgyNkwxMTU1IDE0MS44MjZWMjgwLjgyNkgxMTM2QzExMTcgMjgwLjgyNiAxMDc4IDI4MC44MjYgMTA0MCAyODAuODI2QzEwMDEgMjgwLjgyNiA5NjMgMjgwLjgyNiA5MjQgMjgwLjgyNkM4ODYgMjgwLjgyNiA4NDcgMjgwLjgyNiA4MDkgMjgwLjgyNkM3NzAgMjgwLjgyNiA3MzIgMjgwLjgyNiA2OTMgMjgwLjgyNkM2NTUgMjgwLjgyNiA2MTYgMjgwLjgyNiA1NzggMjgwLjgyNkM1MzkgMjgwLjgyNiA1MDEgMjgwLjgyNiA0NjIgMjgwLjgyNkM0MjQgMjgwLjgyNiAzODUgMjgwLjgyNiAzNDcgMjgwLjgyNkMzMDggMjgwLjgyNiAyNzAgMjgwLjgyNiAyMzEgMjgwLjgyNkMxOTMgMjgwLjgyNiAxNTQgMjgwLjgyNiAxMTYgMjgwLjgyNkM3NyAyODAuODI2IDM5IDI4MC44MjYgMTkgMjgwLjgyNkgwVjEwNy44MjZaJyBmaWxsPScjMDA5OUZGJy8+PC9zdmc+);
  background: rgba(221, 240, 255, 1);
}

.features-section__container .features-section__title {
  /*max-width: 872px;*/
  width: 100%;
  margin: auto;
}

.features-section__title > .section-title__text > .title-text,
.features-section__title > .section-title__text > .description-text {
  text-align: center;
}

.features-section__container .features-section__body {
  position: relative;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 30px;
  margin-top: 40px;
}

@media (min-width: 992px) {
  .features-section__container .features-section__body {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 60px;
    margin-top: 80px;
  }
}

.features-section__body .features-section__body-item {
  width: 80%;
  height: 205px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  z-index: 1;
}

@media (min-width: 992px) {
  .features-section__body .features-section__body-item.box-1,
  .features-section__body .features-section__body-item.box-3 {
    padding-right: 100px;
    padding-left: 60px;
  }

  .features-section__body .features-section__body-item.box-2,
  .features-section__body .features-section__body-item.box-4 {
    padding-left: 100px;
    padding-right: 60px;
  }
}

@media (min-width: 1200px) {
  .features-section__body .features-section__body-item {
    padding-left: 100px;
    padding-right: 100px;
  }
}

.features-section__body .features-section__body-item:before {
  content: "";
  border-radius: 35px;
  box-shadow: 0 4px 17px 3px rgba(0, 81, 199, 0.13);
  background: #ffffff;
  position: absolute;
  border-color: rgba(174, 174, 174, 1);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.features-section__body .features-section__body-item .item-title {
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 1;
}

.features-section__body-item .item-title > .title {
  font-size: 24px;
  font-weight: bold;
  text-align: left;
  color: #000000;
  margin-bottom: 0;
}

.features-section__body .features-section__body-item .item-title > .title-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  border-radius: 100%;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.36);
  background: linear-gradient(
          180deg,
          rgba(0, 176, 255, 1) 0%,
          rgba(15, 122, 255, 1) 100%
  );
}

.features-section__body-item .item-title > .title-icon i{
  color: #fff;
  font-size: 19px;
}

.features-section__body .features-section__body-item .item-description {
  z-index: 1;
  color: rgba(88, 88, 88, 0.81);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  margin-bottom: 0;
  margin-top: 10px;
}

.features-section__body .features-section__body-item.box-1:before,
.features-section__body .features-section__body-item.box-4:before {
  transform: rotateY(0deg) perspective(1000px) skewX(-20deg);
}

.features-section__body .features-section__body-item.box-2:before {
  transform: rotateY(0deg) perspective(1000px) skewX(20deg);
}

.features-section__body .features-section__body-item.box-3:before {
  transform: rotateY(0deg) perspective(1000px) skewX(20deg) rotate(0deg)
  skewY(0deg) rotateX(0deg);
}

.features-section__body .features-section__body-circle {
  position: absolute;
  width: 308px;
  height: 308px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 4px 17px 3px rgba(0, 81, 199, 0.33);
  background: linear-gradient(
          0deg,
          rgba(15, 122, 255, 1) 0%,
          rgba(0, 176, 255, 1) 100%
  );
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.features-section__body .features-section__body-circle > img {
  width: 70%;
}

/*INFO: FAQ styles*/
.faq-section {
  margin-top: 100px;
  width: 100%;
  padding-bottom: 60px;
}

@media (min-width: 1024px) {
  .faq-section {
    padding-bottom: 100px;
  }
}

@media (min-width: 1200px) {
  .faq-section {
    min-height: 959px;
  }
}

.faq-section__body {
  display: flex;
  width: 100%;
  margin-top: 40px;
  position: relative;
  z-index: 1;
}

@media (min-width: 992px) {
  .faq-section__body {
    margin-top: 100px;
  }
}

.faq-section__body-right {
  position: relative;
  z-index: 1;
  margin-left: 60px;
  width: calc(100% / 2 - 60px);
  height: 592px;
}

.faq-section__body-right__container {
  max-width: 554px;
  height: 592px;
  width: 100%;
  position: absolute;
  right: 0;
}

.faq-section__body-right__container:has(.banner-image__1) {
  transform: skewX(-14deg) perspective(1000px) skewY(-11deg);
}

.faq-section__body-right__container:has(.banner-image__2) {
  transform: skewX(-14deg) perspective(1000px) skewY(7deg);
}

.faq-section__body-right__container:has(.banner-image__3) {
  width: 100%;
  height: 100%;
  max-width: unset;
  transform: skewX(-21deg) perspective(1000px) skewY(-17deg) rotateY(-26deg);
}

.faq-section__body-right__container .banner-image__1 {
  position: absolute;
  top: 0;
  left: 10px;
  perspective: 1000px;
  width: 544px;
  height: 589px;
}

.faq-section__body-right__container .banner-image__1-container {
  width: 100%;
  height: 100%;
  border-color: rgba(66, 171, 255, 0.64);
  border-radius: 132px 23px 132px 23px;
  border-style: solid;
  border-width: 2px;
  box-shadow: -2px 9px 17px 6px rgba(0, 0, 0, 0.12);
  overflow: hidden;
}

.banner-image__1-container > .image-background {
  width: 544px;
  height: 589px;
  top: 0;
  left: 0;
  background: center center/ cover no-repeat scroll content-box border-box
  url("../public/assets/images/faq-banner-image.webp");
}

.faq-section__body-right__container .banner-image__2 {
  position: absolute;
  top: 96.5px;
  left: 10px;
  perspective: 1000px;
  width: 509px;
  height: 495px;
}

.banner-image__2 .banner-image__2-container {
  border-color: rgba(229, 231, 235, 1);
  border-style: solid;
  border-width: 0;
  -webkit-mask-size: 100% 100%;
  -webkit-mask-position: 0% 0%;
  width: 100%;
  height: 100%;
}

.banner-image__2-container > .image-background {
  width: 511px;
  height: 495px;
  top: 0;
  left: 0;
  background: center center/ cover no-repeat scroll content-box border-box
  url("../public/assets/images/banner-image.webp");
}

.faq-section__body-right__container .banner-image__3 {
  position: absolute;
  top: 82.5px;
  left: 154.5px;
  perspective: 1000px;
  width: 350px;
  height: 61px;
}

.banner-image__3 .banner-image__3-container {
  border-color: rgba(229, 231, 235, 1);
  border-style: solid;
  border-width: 0;
  -webkit-mask-size: 100% 100%;
  -webkit-mask-position: 0% 0%;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.banner-image__3 .banner-image__3-container > .image-background {
  width: 350px;
  height: 61px;
  top: 0;
  left: 0;
  background: center center/ cover no-repeat scroll content-box border-box
  url("../public/assets/images/products-section-title-image.png");
  margin: 0 auto;
  position: absolute;
}

.faq-section__body-left {
  flex: 1;
}

.faq-section__body-left .faq-item {
  border: 0 !important;
}

.faq-section__body-left .faq-item:not(:first-child) {
  margin-top: 36px;
}

.faq-section__body-left .faq-item .faq-item__btn {
  border: none;
  outline: none;
  padding: 12px 18px;
  text-align: left;
  border-radius: 23px;
  box-shadow: 0 4px 13px 2px rgba(0, 91, 164, 0.08);
  background: rgba(233, 245, 255, 1);
  -webkit-mask-position: 0% 0%;
  -webkit-mask-size: 100% 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-section__body-left .faq-item .faq-item__btn .left-side {
  flex: 2;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.faq-section__body-left .faq-item .faq-item__btn .left-side > .number {
  border-radius: 213px;
  box-shadow: 0 4px 17px 3px rgba(0, 81, 199, 0.33);
  background: linear-gradient(
          0deg,
          rgba(15, 122, 255, 1) 0%,
          rgba(0, 176, 255, 1) 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 25px;
  width: 45px;
  height: 45px;
  flex-shrink: 0;
}

@media (min-width: 768px) {
  .faq-section__body-left .faq-item .faq-item__btn .left-side > .number {
    width: 52px;
    height: 52px;
  }
}

.faq-section__body-left .faq-item .faq-item__btn .left-side > .text {
  font-size: 19px;
  text-align: left;
  margin-left: 14px;
}

.faq-frame-left {
  /*background-image: url("../public/assets/images/faq-section-left-frame.png");
  background-position: left;
  background-size: cover;
  background-repeat: no-repeat;*/
  width: 500px;
  height: 800px;
  position: absolute;
  left: -700px;
  bottom: 0;
}

@media (min-width: 1639px) {
  .faq-frame-left {
    left: -600px !important;;
  }
}

@media (min-width: 1919px) {
  .faq-frame-left {
    left: -400px !important;
  }
}


.faq-frame-left__container {
  transform: rotate(90deg) perspective(1000px);
  width: 100%;
  height: 100%;
}

.faq-frame-left__container .faq-frame-left__body {
  -webkit-mask-image: url(data:image/svg+xml;base64,PHN2ZyBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSdub25lJyAgIHdpZHRoPSc1MjYnIGhlaWdodD0nNTQ0JyB2aWV3Qm94PScwIDAgNTI2IDU0NCcgZmlsbD0nbm9uZScgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48cGF0aCBmaWxsLXJ1bGU9J2V2ZW5vZGQnIGNsaXAtcnVsZT0nZXZlbm9kZCcgZD0nTTIwMy44OTYgNTUuODAzMUMzMDMuMjA0IDM5LjI1NiA0MTcuNzEyIC00Mi4xODU5IDQ4OS42NDcgMjguMjUwNEM1NjIuMTYyIDk5LjI1NDUgNTEwLjEwMyAyMjMuNTA0IDQ3MC42NyAzMTcuMDE5QzQ0MC4zMDEgMzg5LjAzNSAzNzMuOTcxIDQyOS4zMTIgMzA1LjAxMyA0NjYuMTAxQzIzMC4zNDUgNTA1LjkzNSAxNDQuNDY1IDU3My42NDIgNzIuMjAxIDUyOS41OTdDMS4xMDA5NSA0ODYuMjYxIDM3LjYyNjUgMzc5LjEwNyAyNi44MjUzIDI5Ni41NDVDMTguMTkxNSAyMzAuNTUgLTIyLjUxODcgMTYwLjYzNSAxNi45MTcxIDEwNy4wMTlDNTcuMjAxIDUyLjI0OTUgMTM2LjgzMiA2Ni45Nzc2IDIwMy44OTYgNTUuODAzMVonIGZpbGw9JyNDNEM0QzQnLz48L3N2Zz4=);
  background: rgba(0, 176, 255, 0.060);
  -webkit-mask-position: 0% 0%;
  -webkit-mask-size: 100% 100%;
  width: 100%;
  height: 100%;
}

.faq-frame-right {
  background-image: url("../public/assets/images/faq-section-right-frame.png");
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;
  width: 250px;
  height: 600px;
  position: absolute;
  right: 0;
  top: -50%;
}

/*INFO: footer styles*/
.footer {
  width: 100%;
  background-color: rgba(221, 240, 255, 1);
  margin-top: 120px;
}

.footer .footer-frame__1 {
  top: -90px;
  left: 0;
  perspective: 1000px;
  width: 5702px;
  height: 178px;
  position: absolute;
}

.footer .footer-frame__1 .footer-frame__1-container {
  width: 100%;
  height: 100%;
  transform: rotate(0deg) perspective(1000px);
}

.footer .footer-frame__1 .footer-frame__1-container > .frame-image {
  -webkit-mask-image: url(data:image/svg+xml;base64,PHN2ZyBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSdub25lJyB3aWR0aD0nMTE1NScgaGVpZ2h0PScyODEnIHZpZXdCb3g9JzAgMCAxMTU1IDI4MScgZmlsbD0nbm9uZScgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48cGF0aCBmaWxsLXJ1bGU9J2V2ZW5vZGQnIGNsaXAtcnVsZT0nZXZlbm9kZCcgZD0nTTAgMTA3LjgyNkwxOSA4OS44MjY0QzM5IDcyLjgyNjQgNzcgMzcuODI2NCAxMTYgNjYuODI2NEMxNTQgOTUuODI2NCAxOTMgMTg3LjgyNiAyMzEgMTk5LjgyNkMyNzAgMjExLjgyNiAzMDggMTQxLjgyNiAzNDcgODMuODI2NEMzODUgMjYuODI2NCA0MjQgLTIwLjE3MzYgNDYyIDguODI2NDNDNTAxIDM3LjgyNjQgNTM5IDE0MS44MjYgNTc4IDE3MC44MjZDNjE2IDE5OS44MjYgNjU1IDE1My44MjYgNjkzIDEzMC44MjZDNzMyIDEwNy44MjYgNzcwIDEwNy44MjYgODA5IDEzMC44MjZDODQ3IDE1My44MjYgODg2IDE5OS44MjYgOTI0IDE5My44MjZDOTYzIDE4Ny44MjYgMTAwMSAxMzAuODI2IDEwNDAgMTEyLjgyNkMxMDc4IDk1LjgyNjQgMTExNyAxMTguODI2IDExMzYgMTMwLjgyNkwxMTU1IDE0MS44MjZWMjgwLjgyNkgxMTM2QzExMTcgMjgwLjgyNiAxMDc4IDI4MC44MjYgMTA0MCAyODAuODI2QzEwMDEgMjgwLjgyNiA5NjMgMjgwLjgyNiA5MjQgMjgwLjgyNkM4ODYgMjgwLjgyNiA4NDcgMjgwLjgyNiA4MDkgMjgwLjgyNkM3NzAgMjgwLjgyNiA3MzIgMjgwLjgyNiA2OTMgMjgwLjgyNkM2NTUgMjgwLjgyNiA2MTYgMjgwLjgyNiA1NzggMjgwLjgyNkM1MzkgMjgwLjgyNiA1MDEgMjgwLjgyNiA0NjIgMjgwLjgyNkM0MjQgMjgwLjgyNiAzODUgMjgwLjgyNiAzNDcgMjgwLjgyNkMzMDggMjgwLjgyNiAyNzAgMjgwLjgyNiAyMzEgMjgwLjgyNkMxOTMgMjgwLjgyNiAxNTQgMjgwLjgyNiAxMTYgMjgwLjgyNkM3NyAyODAuODI2IDM5IDI4MC44MjYgMTkgMjgwLjgyNkgwVjEwNy44MjZaJyBmaWxsPScjMDA5OUZGJy8+PC9zdmc+);
  background: linear-gradient(
          180deg,
          rgba(33, 150, 243, 0.34) 0%,
          rgba(221, 240, 255, 1) 100%
  );
  -webkit-mask-position: 0% 0%;
  -webkit-mask-size: 100% 100%;
  width: 100%;
  height: 100%;
}

.footer .footer-frame__2 {
  top: -42px;
  left: -2020px;
  perspective: 1000px;
  width: 5714px;
  height: 118px;
  position: absolute;
}

.footer .footer-frame__2 .footer-frame__2-container {
  transform: rotate(180deg) perspective(1000px);
  width: 100%;
  height: 100%;
}

.footer .footer-frame__2 .footer-frame__2-container > .frame-image {
  -webkit-mask-image: url(data:image/svg+xml;base64,PHN2ZyBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSdub25lJyB3aWR0aD0nMTE1NScgaGVpZ2h0PScyODEnIHZpZXdCb3g9JzAgMCAxMTU1IDI4MScgZmlsbD0nbm9uZScgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48cGF0aCBmaWxsLXJ1bGU9J2V2ZW5vZGQnIGNsaXAtcnVsZT0nZXZlbm9kZCcgZD0nTTAgMTA3LjgyNkwxOSA4OS44MjY0QzM5IDcyLjgyNjQgNzcgMzcuODI2NCAxMTYgNjYuODI2NEMxNTQgOTUuODI2NCAxOTMgMTg3LjgyNiAyMzEgMTk5LjgyNkMyNzAgMjExLjgyNiAzMDggMTQxLjgyNiAzNDcgODMuODI2NEMzODUgMjYuODI2NCA0MjQgLTIwLjE3MzYgNDYyIDguODI2NDNDNTAxIDM3LjgyNjQgNTM5IDE0MS44MjYgNTc4IDE3MC44MjZDNjE2IDE5OS44MjYgNjU1IDE1My44MjYgNjkzIDEzMC44MjZDNzMyIDEwNy44MjYgNzcwIDEwNy44MjYgODA5IDEzMC44MjZDODQ3IDE1My44MjYgODg2IDE5OS44MjYgOTI0IDE5My44MjZDOTYzIDE4Ny44MjYgMTAwMSAxMzAuODI2IDEwNDAgMTEyLjgyNkMxMDc4IDk1LjgyNjQgMTExNyAxMTguODI2IDExMzYgMTMwLjgyNkwxMTU1IDE0MS44MjZWMjgwLjgyNkgxMTM2QzExMTcgMjgwLjgyNiAxMDc4IDI4MC44MjYgMTA0MCAyODAuODI2QzEwMDEgMjgwLjgyNiA5NjMgMjgwLjgyNiA5MjQgMjgwLjgyNkM4ODYgMjgwLjgyNiA4NDcgMjgwLjgyNiA4MDkgMjgwLjgyNkM3NzAgMjgwLjgyNiA3MzIgMjgwLjgyNiA2OTMgMjgwLjgyNkM2NTUgMjgwLjgyNiA2MTYgMjgwLjgyNiA1NzggMjgwLjgyNkM1MzkgMjgwLjgyNiA1MDEgMjgwLjgyNiA0NjIgMjgwLjgyNkM0MjQgMjgwLjgyNiAzODUgMjgwLjgyNiAzNDcgMjgwLjgyNkMzMDggMjgwLjgyNiAyNzAgMjgwLjgyNiAyMzEgMjgwLjgyNkMxOTMgMjgwLjgyNiAxNTQgMjgwLjgyNiAxMTYgMjgwLjgyNkM3NyAyODAuODI2IDM5IDI4MC44MjYgMTkgMjgwLjgyNkgwVjEwNy44MjZaJyBmaWxsPScjMDA5OUZGJy8+PC9zdmc+);
  background: rgba(255, 255, 255, 1);
  -webkit-mask-position: 0% 0%;
  -webkit-mask-size: 100% 100%;
  width: 100%;
  height: 100%;
}

.footer .footer-container {
  padding-bottom: 60px;
  padding-top: 60px;
}

.footer-container .footer-bottom {
  border-top: 1px solid rgba(88, 88, 88, 0.15);
  padding-top: 20px;
}

.footer .footer-bottom .footer-bottom__text {
  color: rgba(88, 88, 88, 1);
  font-size: 17px;
  text-align: left;
  margin-bottom: 0;
}

.footer-bottom__socials .social-item {
  width: 23px;
  height: 23px;
}

.footer-bottom__socials .social-item:not(:first-child) {
  margin-left: 24px;
}

.footer-bottom__socials .social-item > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.footer-bottom__socials .social-item > i {
  font-size: 1.5rem;
}

.footer-bottom__socials .social-item .facebook{
  color: #1877f2;
}

.footer-bottom__socials .social-item .youtube{
  color: #ff0000;
}

.footer-bottom__socials .social-item .instagram{
  color: #e1306c;
}

.footer-bottom__socials .social-item .linkedin{
  color: #0a66c2;
}

.footer-bottom__links .link-item {
  text-decoration: none;
  text-align: left;
  color: rgba(88, 88, 88, 1);
  font-size: 17px;
}

.footer-bottom__links .link-item:hover {
  color: #000000;
}

.footer-bottom__links .link-item:not(:first-child) {
  margin-left: 24px;
}

.footer-header {
  padding: 24px 0;
}

.footer-header__left,
.footer-header__right {
  width: 100%;
}

@media (min-width: 992px) {
  .footer-header__left,
  .footer-header__right {
    max-width: 500px;
  }
}

.footer-header__right {
  margin-top: 24px;
}

@media (min-width: 992px) {
  .footer-header__right {
    margin-top: 0;
  }
}

.footer-header__left .footer-header__left-logo {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 30px;
}

.footer-header__left .footer-header__left-logo .logo-wrapper {
  display: inline-block;
  width: 100%;
  position: relative;
}

.footer-header__left .footer-header__left-logo .logo-wrapper > img {
  height: 60px;
}

.footer-header__left .footer-header__left-address {
  color: rgba(88, 88, 88, 1);
  font-size: 15px;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
}

.footer-header__left-contact .contact-item {
  text-decoration: none;
  color: rgba(88, 88, 88, 1);
  font-size: 15px;
  font-weight: bold;
  line-height: 1.6;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@media (min-width: 768px) {
  .footer-header__left-contact .contact-item {
    justify-content: center;
  }
}

.footer-header__left-contact .contact-item:hover {
  color: #000000;
}

.footer-header__left-contact .contact-item:not(:first-child) {
  margin-top: 10px;
}

@media (min-width: 768px) {
  .footer-header__left-contact .contact-item:not(:first-child) {
    margin-left: 30px;
    margin-top: 0;
  }
}

.footer-header__left-contact .contact-item .contact-item__icon {
  width: 24px;
  height: 24px;
  display: inline-block;
  margin-right: 15px;
}

.footer-header__left-contact .contact-item .contact-item__icon > i{
  color: #fff;
  background: #000;
  border-radius: 50%;
  padding: 6px 8px;
}

.footer-header__left-contact .contact-item .contact-item__icon > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.footer-header__right-links .link-item:not(:first-child) {
  margin-top: 14px;
}

.footer-header__right-links .link-item > .link-item__text {
  text-decoration: none;
  color: rgba(88, 88, 88, 1);
  font-size: 15px;
  text-align: left;
}

.footer-header__right-links .link-item > .link-item__text:hover {
  color: #000000;
}

.footer-header__right-subscribe {
  margin-top: 24px;
}

.footer-header__right-subscribe .input-custom {
  flex: 1;
}

.footer-header__right-subscribe .subscribe-btn {
  /*background: linear-gradient(
          90deg,
          rgba(15, 122, 255, 1) 0%,
          rgba(0, 176, 255, 1) 99%
  );*/
  border-radius: 10px;
  border: none;
  outline: none;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.17);
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-weight: bold;
  max-width: 152px;
  width: 100%;
  margin-left: 18px;
  transition: transform ease-out 200ms;
}

.footer-header__right-subscribe .subscribe-btn:active {
  transform: translateY(3px);
}

/*INFO: search modal styles*/
.search-modal .modal-header.modal-close i{
  font-size: 2rem; color: #fff;
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.search-modal .search-modal-body .search-modal-body__input {
  position: relative;
}

.search-modal .search-modal-body .search-modal-body__input > input {
  color: rgba(0, 0, 0, 1);
  padding: 12px 45px 12px 20px;
  width: 100%;
  border-radius: 74px;
  border: 1px solid rgba(217, 217, 217, 1);
  outline: none;
}

.search-modal .search-modal-body .submit-icon{
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  background: 0;
}

.search-modal .search-modal-body .search-modal-body__input i {
  font-size: 1.2rem;
}

@keyframes faq-expand {
  0% {
    top: -100%;
  }
  100% {
    top: 0;
  }
}






/* CUSTOM CSS */
.card{
  --bs-card-border-color: rgba(0,0,0,0.1);
  --bs-card-border-radius: 13px;
  --bs-card-inner-border-radius: 13px;

  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-padding-y: 1rem;
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
}
.card-custom{margin: 30px 0;}
.card-custom .card-header{background: #fff; font-size: 1.2rem; font-weight: 600;}

#navbar{background-color:rgba(221,240,255,1);border-bottom:1px solid rgba(0,0,0,0.05); z-index: 9 !important;}
#navbar .navbar-brand img{height:40px}
.nav-link{padding-left:25px!important;padding-right:25px!important}
.header-search-icon i{cursor:pointer;font-size:1.3rem}
.dropdown:hover .dropdown-menu {display: block; margin-top: 0;}

.ril__toolbar{z-index: 9999;}

.btn-custom{padding: 10px 30px; border-radius:30px; line-height:1.7; font-size:17px; color:#fff; font-weight: 300; /*box-shadow:0 2px 2px 0 rgba(0,0,0,0.23);*/ border:none; outline:none; text-align:center;}
.btn:focus{outline:none}
.btn-custom.btn-primary{background-color:rgba(15,122,255,1)}
.btn-custom.btn-secondary{color:rgba(56,56,56,1);background-color:#fff}
.btn-custom.btn-secondary:hover{background-color:rgba(15,122,255,1);color:#fff}
.button-29{align-items:center;appearance:none;background-image:radial-gradient(100% 100% at 100% 0,#5adaff 0,#5468ff 100%);border:0;border-radius:6px;box-shadow:rgba(45,35,66,.4) 0 2px 4px,rgba(45,35,66,.3) 0 7px 13px -3px,rgba(58,65,111,.5) 0 -3px 0 inset;box-sizing:border-box;color:#fff;cursor:pointer;display:inline-flex;padding:20px 50px;justify-content:center;list-style:none;overflow:hidden;position:relative;text-align:left;text-decoration:none;transition:box-shadow .15s,transform .15s;user-select:none;-webkit-user-select:none;touch-action:manipulation;white-space:nowrap;will-change:box-shadow,transform;font-size:20px;text-transform:uppercase;font-weight:500}
.button-29:hover{box-shadow:rgba(45,35,66,.4) 0 4px 8px,rgba(45,35,66,.3) 0 7px 13px -3px,#3c4fe0 0 -3px 0 inset;}
.button-29:not(.scroll-download-btn):hover{transform:translateY(-2px)}
.button-29:active{transform:translateY(2px)}

.download-btn i{margin-right: 10px;}
.scroll-download-btn{position: fixed; top: 80px; left: 50%; transform: translate(-50%, 0); z-index: 8;}
.scroll-download-btn:hover{box-shadow:rgba(45,35,66,.4) 0 4px 18px,rgba(45,35,66,.3) 0 7px 13px -3px,#3c4fe0 0 -3px 0 inset;}
.downloading-dl{pointer-events: none; opacity: 0.5; padding: 20px 20px;}

.ellipsis-text{overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical;}
.ellipsis-2lines{-webkit-line-clamp: 2;}
.ellipsis-3lines{-webkit-line-clamp: 3;}

.languages-list-link{text-decoration-style: dotted; cursor: none;}
.languages-list{text-align: left; list-style-type: circle; display: flex; flex-wrap: wrap;}
.languages-list li{flex-basis: 33.333%;}
.tooltip-inner {max-width: 500px;}

#details, #static-page{padding:50px 0}
#details .page-title, #static-page .page-title{font-weight: 700; font-size: 2.6rem; text-align: center; margin-bottom: 30px;}
#static-page h4{font-weight: 700; margin: 0 0 20px}

.overall-info-section .avatar{flex-basis:200px;flex-grow:0;flex-shrink:0}
.overall-info-section .avatar img{border-radius: 20px; width: 200px; height: 200px;}
.overall-info-section .info{padding-left:20px}
.overall-info-section .main-title{font-size:1.8rem;font-weight:700; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;}
.overall-info-section .info .stats-info{margin:20px 0}
.stats-info .item .count .bi{color:#F39C12}
.stats-info .item .count .number{font-size:1.1rem;font-weight:600}
.stats-info .item .text{color:rgba(0,0,0,0.5);font-size:.9rem}
.stats-info .seperator{transform:rotate(90deg) perspective(1000px);width:40px;margin:5px}
.stats-info .seperator .line{border:1px solid rgba(0,0,0,0.1)}
.description-section{font-size: 16px; color:rgba(0,0,0,0.8)}
.swiper{width:100%;height:100%}
.swiper-slide{text-align:center;font-size:18px;background:#fff;display:flex;justify-content:center;align-items:center}
.swiper-slide img{display:block; height:auto; width:auto; border-radius: 5px; cursor: zoom-in;}

.swiper-button-prev,.swiper-button-next{background:rgba(255,255,255,1);width:40px;height:40px;box-shadow:rgba(0,0,0,0.14) 0 4px 8px 0;border-radius:50%;color:rgba(0,0,0,0.6)}
.swiper-button-next:after, .swiper-button-prev:after{font-size: 15px; font-weight: 600;}

.details-section .card-body h5{margin-bottom: 15px; font-weight: 700;}
.details-section .card-body{font-size: 0.95rem;}
.details-section .video {width: 80%; margin: 0 auto;}

.info-list{display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between;}
.info-list .info-item{display: flex; flex-direction: row; flex-wrap: wrap; flex-basis: 33.333%; color: rgba(0,0,0,0.5); margin-bottom: 40px; margin-top: 10px;}
.info-item .icon{flex-basis: 30px;}
.info-item .icon i{font-size: 1rem;}
.info-item .text .content{color: rgba(0,0,0,0.9); margin-top: 5px; font-size: 0.95rem;}

.box-title{font-size: 1.2rem; font-weight: 500; margin-bottom: 5px;}
.regular-app-list .item{display: block; clear: both; transition: all .2s ease-in-out;border-bottom: 1px solid rgba(0,0,0,0.1); padding: 20px 0; color: inherit;}
.regular-app-list .item .item-head{display: flex; flex-direction: row; align-items: center;}
.regular-app-list .item:hover{transform: translateY(-2px);}
.regular-app-list .item .avatar{flex-basis: 55px;flex-grow: 0; flex-shrink: 0;}
.regular-app-list .item .avatar img{width: 55px; height: 55px; border-radius: 10px;}
.regular-app-list .item .info{padding-left: 15px;}
.regular-app-list .item .info .title{font-size: 1.1rem; font-weight: 700; line-height: 1.3; margin-bottom: 3px; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;}
.regular-app-list .item .item-body .description{margin-top: 10px; font-size: 0.9rem; color: rgba(0,0,0,0.5); overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical;}
.regular-app-list .item .item-body .actions{text-align: center; margin-top: 15px;}
.regular-app-list .item:hover .title{color: rgba(var(--bs-link-color-rgb),var(--bs-link-opacity,1));}
.regular-app-list .item .info .stats{color: rgba(0,0,0,0.5); font-size: 0.8rem;}
.regular-app-list .item:hover .stats{color: rgba(0,0,0,0.7);}
.regular-app-list .item:hover .item-body .description{color: rgba(0,0,0,0.7);}

.border-list .box-title{margin-bottom: 20px;}
.border-list .item{border: 1px solid rgba(0,0,0,0.1); border-radius: 15px; padding: 20px;}

.screenshots-list .item{padding: 0 5px;}
.screenshots-list .item img{border-radius: 10px; cursor: zoom-in;}
.screenshots-list .item:active, .screenshots-list .item:focus{border:0; outline: 0;}
.slick-dots li button:before, .slick-next:before, .slick-prev:before{font-family: bootstrap-icons !important;}
.slick-next:before, [dir=rtl] .slick-prev:before{content: '\F135'}
.slick-prev:before, [dir=rtl] .slick-prev:before{content: '\F12C'}
.slick-next, .slick-prev{width: inherit; height: inherit;}
.slick-next{right: 10px !important; background: none;}
.slick-prev{z-index: 999; left: 10px !important;}
.slick-next:before, .slick-prev:before{font-size: 40px; opacity: 1; background: rgba(255,255,255,1); border-radius: 50%; box-shadow: rgba(0,0,0,0.14) 0 4px 8px 0; color: rgba(0,0,0,0.6);}

.contact-us-page .contact-image{max-width: 500px;}